// Typography
$font-family-sans-serif:  'Nunito', sans-serif;
$font-size-base:          0.9rem;
$line-height-base:        1.6;

@import url(https://cdnjs.cloudflare.com/ajax/libs/simple-line-icons/2.4.1/css/simple-line-icons.css);
@import url(https://fonts.googleapis.com/css?family=Comfortaa:200,300,400,700);
$headings-font-family: Comfortaa;

$body-bg:    #f8fafc;
$primary:    #937144;
$secondary: #6c6c6c;
$success:    #53ad11;
$danger:     #f5075f;
$info:       #d9bd2a;
$warning:    #eab60d;
$light:      #ececec;
$dark:       #222222;
$gray-light: #E6E6E6;
$gray-dark:  #d1d1d1;
$spacer:     1rem;
//$btn-border-radius: 1.35rem;

// Carousel
$carousel-transition-duration: .6s;
$carousel-transition:          transform $carousel-transition-duration ease-in-out; // Define transform transition first if using multiple transitions (e.g., `transform 2s ease, opacity .5s ease-out`)
