.vh-75 {
  height: 75vh !important;
}

.vh-50 {
  height: 50vh !important;
}

.vh-25 {
  height: 25vh !important;
}

.w-90 {
  width: 90%!important;
}

.img-responsive {
  @extend .img-fluid;
  @extend .img-thumbnail;
  @extend .rounded;
  background-color: transparent;
  border: none;
  padding: 0;
}

.card-img-top {
  width: 100%;
  height: 50vw;
  object-fit: cover;
}

@include media-breakpoint-up(sm) { .card-img-top {height: 40vw;} }
@include media-breakpoint-up(md) { .card-img-top {height: 30vw;} }
@include media-breakpoint-up(lg) { .card-img-top {height: 20vw;} }
@include media-breakpoint-up(xl) { .card-img-top {height: 20vw;} }
@include media-breakpoint-up(xxl) { .card-img-top {height: 15vw;} }

.carousel-item {
  min-height: 10vh;
  height: 50vh;
  max-height: 50vh;
  overflow: hidden;
  width: auto;

  & > img {
    width: auto;
    height: 100%;
  }
}

.overflow-y-auto {
  @include media-breakpoint-down(md) {
    overflow-x: hidden;
    overflow-y: auto;
    max-height: 100vh;
  }
}

.rounded-0 {
  border-radius: 0;
}

.btn.btn-file {
  border-radius: 0;

  & > input {
    border-radius: 0;
  }
}

.primary-color-scheme {
  background-color: $gray-light;
  color: black;
}

.secondary-color-scheme {
  background-color: $gray-dark;
  color: black;
}

//.preview-height {
//    max-height: 200px!important;
//}
//
//.img-fluid {
//    display: block;
//    width: auto;
//    max-height: 100%;
//}

//.img-thumbnail {
//    border: none;
//    background-color: transparent;
//    height: 75%;
//}

.navbar-brand > img {
    min-width: 175px;
    max-width: 15vw;
}

//.navbar-brand > img {
//  max-width: 90vw;
//}

@include media-breakpoint-up(sm) { .navbar-brand > img {max-width: 50vw;} }
@include media-breakpoint-up(md) { .navbar-brand > img {max-width: 40vw;} }
@include media-breakpoint-up(lg) { .navbar-brand > img {max-width: 25vw;} }
@include media-breakpoint-up(xl) { .navbar-brand > img {max-width: 25vw;} }
@include media-breakpoint-up(xxl) { .navbar-brand > img {max-width: 25vw;} }

.header-overflow {
  overflow-y: auto;
}

.home-image {height: 50vh;max-height: 50vh;}

@include media-breakpoint-up(sm) {  .home-image {height: 50vh;max-height: 50vh;} }
@include media-breakpoint-up(md) {  .home-image {height: 55vh;max-height: 55vh;} }
@include media-breakpoint-up(lg) {  .home-image {height: 60vh;max-height: 60vh;} }
@include media-breakpoint-up(xl) {  .home-image {height: 65vh;max-height: 65vh;} }
@include media-breakpoint-up(xxl) { .home-image {height: 110vh;max-height: 110vh;} } // 70vh
