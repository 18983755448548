.bg-secondary-20 {
    background-color: shade-color($secondary, 20%)!important;
}

.bg-light-gray {
    background-color: $gray-light;
}

.border-secondary-20 {
    border-color: shade-color($secondary, 20%)!important;
}