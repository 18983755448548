// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');

// Variables
@import 'variables';

// Bootstrap
@import '~bootstrap/scss/bootstrap';
@import '~bootstrap-icons/font/bootstrap-icons';

// FilePicker
@import '~bootstrap-fileinput/scss/fileinput';
@import '~filepond/dist/filepond';
@import '~filepond-plugin-image-preview/dist/filepond-plugin-image-preview';
@import "~filepond-plugin-image-edit/dist/filepond-plugin-image-edit.css";

// MultiSelect
@import "~bootstrap-select/sass/bootstrap-select";



// Customized
@import 'dropdowns';
@import 'colors';
@import "utilities";
@import "orders";