.inner-dropdown {
    @extend .dropdown-menu-dark;
    background: lighten($secondary, 25%);
    & a {
        color: black!important;
    }
}

.show.dropdown-toggle::after {
    transform: rotate(180deg);
}
@include media-breakpoint-down(l) {
    .navbar {
        max-height: 100vh!important;
        overflow-y: scroll!important;
    }
}
@include media-breakpoint-up(xl) {
    .navbar {
        overflow-y: inherit!important;
    }
}
